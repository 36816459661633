import request from '@/utils/request'
// 获取我的信息
export function chat(params) {
  return request({
    url: 'api/ai/chat',
    params: params,
    method: 'get'
  })
}
// 获取流式信息
export function chat2(params) {
  return request({
    url: 'api/aiv2/chat',
    params: params,
    method: 'get'
  })
}
// 规范详情接口
export function getGuifanInfo(params) {
  return request({
    url: 'api/aiv2/getGuifanInfoV2',
    params: params,
    method: 'get'
  })
}
// 获取历史记录接口
export function gethistorymsg(params) {
  return request({
    url: 'api/aiv2/gethistorymsg',
    params: params,
    method: 'get'
  })
}
// 规范查询历史接口
export function gethistorymsgV2(params) {
  return request({
    url: 'api/aiv2/gethistorymsgV2',
    params: params,
    method: 'get'
  })
}
// 清空历史记录接口
export function cleanusermsg(params) {
  return request({
    url: 'api/aiv2/cleanusermsg',
    params: params,
    method: 'get'
  })
}
